/* eslint-disable prettier/prettier */
import { ReactNode, VFC } from 'react';
import styles from './InputItem.module.scss';

type Props = {
  children?: ReactNode | ReactNode[];
  justifyContent?: string;
  margin?: string;
  title: string;
  description?: string | string[];
};

export const InputItem: VFC<Props> = (props) => {
  return (
    <div className={styles['item-wrap']}>
      <p className={styles['item-title']}>{props.title}</p>
      {Array.isArray(props.description)
        ? props.description.map((d, i) => (
          <p key={i} className={styles['item-description']}>{d}</p>))
        : props.description && <p className={styles['item-description']}>{props.description}</p>}
      <div className={styles['item-contents']}>{props.children}</div>
    </div>
  );
};
