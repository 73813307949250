import { useLayoutEffect, useState, VFC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CommonButton } from '../components/CommonButton';
import { InfoArea } from '../components/InfoArea';
import { InputItem } from '../components/InputItem';
import { PageTitle } from '../components/PageTitle';
import { RadioBtnForm } from '../components/RadioBtnForm';
import { SelectBoxForm } from '../components/SelectBoxForm';
import { TextAreaForm } from '../components/TextAreaForm';
import { TextForm } from '../components/TextForm';
import {
  BeneficiarySelectOptions,
  InsuranceReceiptAccountInfoOptionsList,
  bankTypeList,
  branchTypeList,
  accountTypeList
} from '../util/const';
import styles from './InputInfo.module.scss';
import { useForm } from 'react-hook-form';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterDateFns';
import jaLocale from 'date-fns/locale/ja';
import { Stack, TextField } from '@mui/material';
import { registGeneralForm } from '../hooks/useInputInfo';
import { convertStr } from '../util/convert';
import { FileUploader } from '../components/FileUploader';
import { selectInputInfoState, InputInfoState, inputInfoSlice } from '../store/inputInfo';
import { selectAccidentState } from '../store/accident';
import camera from '../assets/images/common/photo_camera_black_48dp.svg';
import fileImg from '../assets/images/common/common_file_icon.svg';
import { loadingSlice } from '../store/loading';
import { errorSlice } from '../store/error';

// eslint-disable-next-line complexity
export const InputInfo: VFC = () => {
  const [state, setState] = useState<InputInfoState>(useSelector(selectInputInfoState));
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit'
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // APIエラーメッセージ切り替え用state
  const [apiError, setApiError] = useState('');
  // 口座情報入力欄の表示フラグ
  const claimFlg = useSelector(selectAccidentState).claim_flg;
  const webBillingNumber = useSelector(selectAccidentState).web_billing_number;
  const insuranceType = useSelector(selectAccidentState).insurance_type;
  useLayoutEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' });
    setState((prev) => ({
      ...prev,
      webBillingNumber: webBillingNumber
    }));
  }, []);

  // datepickerのtextfieldのスタイル指定
  const myStyle = {
    '& .MuiOutlinedInput-root': {
      width: '100%',
      maxWidth: '400px',
      height: '25px',
      '& fieldset': {
        borderColor: '#808080' // 通常時のボーダー色(アウトライン)
      },
      '&:hover fieldset': {
        borderColor: '#808080' // ホバー時のボーダー色(アウトライン)
      },
      '&.Mui-focused fieldset': {
        borderColor: '#2c2c2c !important'
      }
    },
    '& .Mui-error': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#808080 !important'
      }
    }
  };
  const toolBarStyle = {
    '& .PrivatePickersToolbar-root': {
      display: 'block !important'
    }
  };
  const onSubmit = (): void => {
    setApiError('');
    clearErrors('checkDocument');
    clearErrors('checkExtensionDocument');
    if (requireDocumentUrlCheck()) {
      setError('requireDocument', { type: 'required', message: 'error message' });
    } else {
      clearErrors('requireDocument');
      handleClick();
    }
  };
  const onError = (): void => {
    setApiError('');
    clearErrors('checkDocument');
    clearErrors('checkExtensionDocument');
    if (requireDocumentUrlCheck()) {
      setError('requireDocument', { type: 'required', message: 'error message' });
    } else {
      clearErrors('requireDocument');
    }
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>): void => {
    const target = event.target;
    setState((prev) => ({
      ...prev,
      [target.name]: target.value
    }));
  };
  const handleUpload4RequireDoc = (files: FileList, groupIndex: number): void => {
    let hasErrorFlg = false;
    clearErrors('checkDocument');
    clearErrors('checkExtensionDocument');
    if (requireDocumentUrlSizeCheck(files[0].size)) {
      setError('checkDocument', { type: 'fileSize', message: 'error message' });
      hasErrorFlg = true;
    }
    if (requireDocumentsExtensionCheck(files[0].type)) {
      setError('checkExtensionDocument', { type: 'fileExtension', message: 'error message' });
      hasErrorFlg = true;
    }
    if (hasErrorFlg) {
      deleteDocumentUrl(groupIndex);
      return;
    }
    let imageSrc = fileImg;
    if (files[0].type.startsWith('image/')) {
      imageSrc = URL.createObjectURL(files[0]);
    }
    if (groupIndex === 99) {
      setState((prev) => ({
        ...prev,
        cashCardUrl: { file: files[0], src: imageSrc }
      }));
    } else {
      const newArray = [...state.requireDocumentUrls];
      newArray[groupIndex] = { file: files[0], src: imageSrc };
      setState((prev) => ({
        ...prev,
        requireDocumentUrls: state.isRequireDocumentUrls(newArray) ? newArray : prev.requireDocumentUrls
      }));
    }
  };
  const handleClick = async (): Promise<void> => {
    // ローディングスピナーを表示する
    dispatch(loadingSlice.actions.loadingOpen());
    const param = { requireDocumentPath: '', cashCardPath: state.cashCardUrl.src, claimFlg: claimFlg, registFlg: false };
    // 登録前のサーバーチェック用に詰め替え
    let documentUrls = '';
    state.requireDocumentUrls.forEach(function (fileObj) {
      if (fileObj.src !== camera && documentUrls.length == 0) {
        documentUrls = fileObj.src;
      } else if (fileObj.src !== camera && documentUrls.length != 0) {
        documentUrls = documentUrls + ',' + fileObj.src;
      }
    });
    param.requireDocumentPath = documentUrls;
    const res = await registGeneralForm(state, param);
    // ローディングスピナーを非表示にする
    dispatch(loadingSlice.actions.loadingClose());
    // エラーメッセージがある場合はapiExeStatに渡す。成功時はメッセージなしで入力内容確認画面へ遷移する
    if (res.status === 200) {
      dispatch(inputInfoSlice.actions.updateInputInfo(state));
      navigate(`/confirmInfo`);
    } else if (
      (res.status === 401 && res.message === 'Unauthorized') ||
      (res.status === 403 && res.message === 'User is not authorized to access this resource')
    ) {
      dispatch(
        errorSlice.actions.updateErrorPage({
          errorTitle: 'セッション情報の確認ができませんでした。',
          errorMessage: 'SMSまたはメールでご案内したURLから再度アクセスをお願いいたします。'
        })
      );
      navigate(`/systemError`);
    } else if ((res.status === 403 && res.message !== 'User is not authorized to access this resource') || res.status === 500) {
      navigate(`/systemError`);
    } else {
      setApiError(res.message);
    }
  };
  const deleteDocumentUrl = (index: number): void => {
    if (index === 99) {
      setState((prev) => ({
        ...prev,
        cashCardUrl: { file: null, src: camera }
      }));
    } else {
      const newArray = [...state.requireDocumentUrls];
      newArray[index] = { file: null, src: camera };
      setState((prev) => ({
        ...prev,
        requireDocumentUrls: state.isRequireDocumentUrls(newArray) ? newArray : prev.requireDocumentUrls
      }));
    }
  };
  // 添付ファイルサイズチェック
  const requireDocumentUrlSizeCheck = (fileSize: number): boolean => {
    const sizeLimit = 1024 * 1024 * 30;
    let errFlg = false;
    if (fileSize > sizeLimit) {
      errFlg = true;
    }
    return errFlg;
  };

  // 添付ファイル拡張子チェック
  const requireDocumentsExtensionCheck = (fileType: string): boolean => {
    const allowExts: string[] = ['jpeg', 'jpg', 'png', 'pdf'];
    const fileExtension = fileType.split('/').pop();
    // 拡張子をチェック
    let errFlg = false;
    if (!fileExtension || !allowExts.includes(fileExtension)) {
      errFlg = true;
    }
    return errFlg;
  };
  // 添付ファイル必須チェック
  const requireDocumentUrlCheck = (): boolean => {
    let errFlg = true;
    state.requireDocumentUrls.forEach(function (fileObj) {
      if (fileObj.src !== camera) {
        errFlg = false;
      }
    });
    return errFlg;
  };
  // 添付ファイルエリア追加
  const addFile = (): void => {
    if (!state.fileViewFlgList[0]) {
      setState((prev) => ({
        ...prev,
        fileViewFlgList: [true, false, false]
      }));
    } else if (!state.fileViewFlgList[1]) {
      setState((prev) => ({
        ...prev,
        fileViewFlgList: [true, true, false]
      }));
    } else if (!state.fileViewFlgList[2]) {
      setState((prev) => ({
        ...prev,
        fileViewFlgList: [true, true, true]
      }));
    }
  };
  // 添付ファイルエリア削除
  const deleteFile = (): void => {
    const newArray = [...state.requireDocumentUrls];
    let delIndex = 0;
    if (state.fileViewFlgList[2]) {
      setState((prev) => ({
        ...prev,
        fileViewFlgList: [true, true, false]
      }));
      delIndex = 14;
    } else if (state.fileViewFlgList[1]) {
      setState((prev) => ({
        ...prev,
        fileViewFlgList: [true, false, false]
      }));
      delIndex = 9;
    } else if (state.fileViewFlgList[0]) {
      setState((prev) => ({
        ...prev,
        fileViewFlgList: [false, false, false]
      }));
      delIndex = 4;
    }
    newArray.forEach(function (fileObj, index) {
      if (index > delIndex) {
        newArray[index] = { file: null, src: camera };
      }
    });
    setState((prev) => ({
      ...prev,
      requireDocumentUrls: state.isRequireDocumentUrls(newArray) ? newArray : prev.requireDocumentUrls
    }));
  };
  return (
    <div className={styles['wrap']}>
      <PageTitle value="保険金請求フォーム"></PageTitle>
      <div className={styles['contents']}>
        <div className={styles['description']}>
          <p>以下の項目に必要事項をご入力のうえ、「入力内容の確認」ボタンを押してください。</p>
          <p>※ブラウザの「戻る」「進む」ボタンを使用しますと、正しく動作しない場合がございますので、ご注意ください。</p>
        </div>
        {claimFlg == '1' && (
          <InfoArea title="事故状況" requireStatus={1}>
            <InputItem title="事故日" justifyContent="right" margin="0 0 10px 0">
              <LocalizationProvider dateAdapter={DateAdapter} locale={jaLocale} style={{ height: '25px' }} target={'accidentDate'}>
                <Stack spacing={3} style={{ height: '25px' }}>
                  <DatePicker
                    value={new Date(state.accidentDate)}
                    inputFormat="yyyy/MM/dd"
                    mask="____/__/__"
                    minDate={new Date('2017/01/01')}
                    maxDate={new Date()}
                    toolbarTitle="日付選択"
                    cancelText="キャンセル"
                    okText="選択"
                    DialogProps={{ sx: toolBarStyle }}
                    toolbarFormat="yyyy年MM月dd日"
                    onChange={(newValue) => {
                      if (newValue) {
                        setState((prev) => ({
                          ...prev,
                          accidentDate: convertStr(newValue)
                        }));
                        setValue('accidentDate', convertStr(newValue));
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        autoComplete="off"
                        id="accidentDate"
                        disabled
                        sx={myStyle}
                        {...params}
                        {...register('accidentDate', {
                          required: true,
                          pattern: /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/,
                          validate: {
                            futureCheck: (value: string) => (new Date(value) > new Date() ? false : true),
                            invalidDateCheck: () => (state.accidentDate === 'invalidDate' ? false : true)
                          }
                        })}
                      />
                    )}
                  />
                </Stack>
              </LocalizationProvider>
              <div className={styles['error']}>
                {errors.accidentDate && errors.accidentDate.type === 'required' && <span role="alert">必須項目です。</span>}
                {errors.accidentDate && (errors.accidentDate.type === 'pattern' || errors.accidentDate.type === 'invalidDateCheck') && (
                  <span role="alert">日付の形式が不正です。</span>
                )}
                {errors.accidentDate && errors.accidentDate.type === 'futureCheck' && <span role="alert">未来日は指定できません。</span>}
              </div>
            </InputItem>
            <InputItem title="事故状況" description={'事故状況を具体的に記載してください。（300文字以内）'}>
              <TextAreaForm
                maxlength={300}
                value={state.accidentSituation}
                target={'accidentSituation'}
                onChange={handleChange}
                register={register}
                validation={{ required: true, maxLength: 300 }}
              ></TextAreaForm>
              <div className={styles['error']}>
                {errors.accidentSituation && errors.accidentSituation.type === 'required' && <span role="alert">必須項目です。</span>}
                {errors.accidentSituation && errors.accidentSituation.type === 'maxLength' && (
                  <span role="alert">300文字以内で入力してください。</span>
                )}
              </div>
            </InputItem>
          </InfoArea>
        )}
        <InfoArea title="資料添付" requireStatus={1}>
          <p>カメラアイコンをタップし、ご請求に必要となる以下の資料を画像添付ください。</p>
          {claimFlg == '1' && (
            <>
              <ul className={styles['list']}>
                <li>損害状況のわかる写真</li>
                <li>修理見積書</li>
              </ul>
              <div className={styles['annotation']}>
                <p>※写真画像について</p>
                <p>建物の場合、建物の全景と被害箇所の写真を撮影ください。</p>
                <p>※必須書類について</p>
                <p>ご提出いただいた書類では損害が確認できない場合は、追加の書類をご依頼する場合があります。</p>
                <p>※カメラでの撮影が出来ない場合は、写真を選択して送信してください。</p>
                <p>※添付できる画像サイズ・形式は、１ファイルあたり最大30MBまでのJPEG/JPG/PNG/PDFファイルのみです。</p>
              </div>
            </>
          )}
          {claimFlg == '0' && insuranceType == '1' && (
            <>
              <ul className={styles['list']}>
                <li>損害状況のわかる写真</li>
                <li>修理見積書など損害額のわかる資料</li>
              </ul>
              <div className={styles['annotation']}>
                <p>※必須書類について</p>
                <p>ご提出いただいた書類では損害が確認できない場合は、追加の書類をご依頼する場合があります。</p>
                <p>※カメラでの撮影が出来ない場合は、写真を選択して送信してください。</p>
                <p>※添付できる画像サイズ・形式は、１ファイルあたり最大30MBまでのJPEG/JPG/PNG/PDFファイルのみです。</p>
              </div>
            </>
          )}
          {claimFlg == '0' && insuranceType == '2' && (
            <>
              <ul className={styles['list']}>
                <li>診断書</li>
                <li>申告書</li>
                <li>診察券</li>
                <li>領収書</li>
              </ul>
              <div className={styles['annotation']}>
                <p>※必須書類について</p>
                <p>ご提出いただいた書類では損害が確認できない場合は、追加の書類をご依頼する場合があります。</p>
                <p>※カメラでの撮影が出来ない場合は、写真を選択して送信してください。</p>
                <p>※添付できる画像サイズ・形式は、１ファイルあたり最大30MBまでのJPEG/JPG/PNG/PDFファイルのみです。</p>
              </div>
            </>
          )}
          <div className={styles['file-upload-area']}>
            <FileUploader
              id="requireDocument"
              groupIndex={0}
              onChange={handleUpload4RequireDoc}
              file={state.requireDocumentUrls[0]}
              inputInfoState={state}
              deleteDocumentUrl={deleteDocumentUrl}
            ></FileUploader>
            <FileUploader
              id="requireDocument"
              groupIndex={1}
              onChange={handleUpload4RequireDoc}
              file={state.requireDocumentUrls[1]}
              inputInfoState={state}
              deleteDocumentUrl={deleteDocumentUrl}
            ></FileUploader>
            <FileUploader
              id="requireDocument"
              groupIndex={2}
              onChange={handleUpload4RequireDoc}
              file={state.requireDocumentUrls[2]}
              inputInfoState={state}
              deleteDocumentUrl={deleteDocumentUrl}
            ></FileUploader>
            <FileUploader
              id="requireDocument"
              groupIndex={3}
              onChange={handleUpload4RequireDoc}
              file={state.requireDocumentUrls[3]}
              inputInfoState={state}
              deleteDocumentUrl={deleteDocumentUrl}
            ></FileUploader>
            <FileUploader
              id="requireDocument"
              groupIndex={4}
              onChange={handleUpload4RequireDoc}
              file={state.requireDocumentUrls[4]}
              inputInfoState={state}
              deleteDocumentUrl={deleteDocumentUrl}
            ></FileUploader>
          </div>
          {state.fileViewFlgList[0] && (
            <div className={styles['file-upload-area']}>
              <FileUploader
                id="requireDocument"
                groupIndex={5}
                onChange={handleUpload4RequireDoc}
                file={state.requireDocumentUrls[5]}
                inputInfoState={state}
                deleteDocumentUrl={deleteDocumentUrl}
              ></FileUploader>
              <FileUploader
                id="requireDocument"
                groupIndex={6}
                onChange={handleUpload4RequireDoc}
                file={state.requireDocumentUrls[6]}
                inputInfoState={state}
                deleteDocumentUrl={deleteDocumentUrl}
              ></FileUploader>
              <FileUploader
                id="requireDocument"
                groupIndex={7}
                onChange={handleUpload4RequireDoc}
                file={state.requireDocumentUrls[7]}
                inputInfoState={state}
                deleteDocumentUrl={deleteDocumentUrl}
              ></FileUploader>
              <FileUploader
                id="requireDocument"
                groupIndex={8}
                onChange={handleUpload4RequireDoc}
                file={state.requireDocumentUrls[8]}
                inputInfoState={state}
                deleteDocumentUrl={deleteDocumentUrl}
              ></FileUploader>
              <FileUploader
                id="requireDocument"
                groupIndex={9}
                onChange={handleUpload4RequireDoc}
                file={state.requireDocumentUrls[9]}
                inputInfoState={state}
                deleteDocumentUrl={deleteDocumentUrl}
              ></FileUploader>
            </div>
          )}
          {state.fileViewFlgList[1] && (
            <div className={styles['file-upload-area']}>
              <FileUploader
                id="requireDocument"
                groupIndex={10}
                onChange={handleUpload4RequireDoc}
                file={state.requireDocumentUrls[10]}
                inputInfoState={state}
                deleteDocumentUrl={deleteDocumentUrl}
              ></FileUploader>
              <FileUploader
                id="requireDocument"
                groupIndex={11}
                onChange={handleUpload4RequireDoc}
                file={state.requireDocumentUrls[11]}
                inputInfoState={state}
                deleteDocumentUrl={deleteDocumentUrl}
              ></FileUploader>
              <FileUploader
                id="requireDocument"
                groupIndex={12}
                onChange={handleUpload4RequireDoc}
                file={state.requireDocumentUrls[12]}
                inputInfoState={state}
                deleteDocumentUrl={deleteDocumentUrl}
              ></FileUploader>
              <FileUploader
                id="requireDocument"
                groupIndex={13}
                onChange={handleUpload4RequireDoc}
                file={state.requireDocumentUrls[13]}
                inputInfoState={state}
                deleteDocumentUrl={deleteDocumentUrl}
              ></FileUploader>
              <FileUploader
                id="requireDocument"
                groupIndex={14}
                onChange={handleUpload4RequireDoc}
                file={state.requireDocumentUrls[14]}
                inputInfoState={state}
                deleteDocumentUrl={deleteDocumentUrl}
              ></FileUploader>
            </div>
          )}
          {state.fileViewFlgList[2] && (
            <div className={styles['file-upload-area']}>
              <FileUploader
                id="requireDocument"
                groupIndex={15}
                onChange={handleUpload4RequireDoc}
                file={state.requireDocumentUrls[15]}
                inputInfoState={state}
                deleteDocumentUrl={deleteDocumentUrl}
              ></FileUploader>
              <FileUploader
                id="requireDocument"
                groupIndex={16}
                onChange={handleUpload4RequireDoc}
                file={state.requireDocumentUrls[16]}
                inputInfoState={state}
                deleteDocumentUrl={deleteDocumentUrl}
              ></FileUploader>
              <FileUploader
                id="requireDocument"
                groupIndex={17}
                onChange={handleUpload4RequireDoc}
                file={state.requireDocumentUrls[17]}
                inputInfoState={state}
                deleteDocumentUrl={deleteDocumentUrl}
              ></FileUploader>
              <FileUploader
                id="requireDocument"
                groupIndex={18}
                onChange={handleUpload4RequireDoc}
                file={state.requireDocumentUrls[18]}
                inputInfoState={state}
                deleteDocumentUrl={deleteDocumentUrl}
              ></FileUploader>
              <FileUploader
                id="requireDocument"
                groupIndex={19}
                onChange={handleUpload4RequireDoc}
                file={state.requireDocumentUrls[19]}
                inputInfoState={state}
                deleteDocumentUrl={deleteDocumentUrl}
              ></FileUploader>
            </div>
          )}
          <div style={{ textAlign: 'center' }}>
            {!state.fileViewFlgList[2] && (
              <CommonButton
                id="add"
                label="追加"
                onClick={() => {
                  addFile();
                }}
                color={'#fff'}
                backGroundColor={'#00bfff'}
              ></CommonButton>
            )}
            {state.fileViewFlgList[0] && (
              <CommonButton
                id="delete"
                label="削除"
                onClick={() => {
                  deleteFile();
                }}
                color={'#fff'}
                backGroundColor={'#75757c'}
              ></CommonButton>
            )}
          </div>
          <div className={styles['error']}>
            {errors.requireDocument && errors.requireDocument.type === 'required' && <span role="alert">必須項目です。</span>}
            {errors.checkDocument && errors.checkDocument.type === 'fileSize' && <span role="alert">ファイルサイズが超過しています。</span>}
            {errors.checkExtensionDocument && errors.checkExtensionDocument.type === 'fileExtension' && (
              <span role="alert">ファイルの拡張子がアップロード対象外です。</span>
            )}
          </div>
        </InfoArea>
        <InfoArea title="保険金受取口座情報" requireStatus={1}>
          <div className={styles['item-area']}>
            <InputItem title="受取人">
              <SelectBoxForm
                value={state.beneficiary}
                target={'beneficiary'}
                onChange={handleChange}
                SelectBoxOptions={BeneficiarySelectOptions}
              ></SelectBoxForm>
            </InputItem>
          </div>
          {state.beneficiary === '1' && (
            <>
              <div className={styles['item-area']}>
                <InputItem title="受取人氏名(漢字)">
                  <TextForm
                    maxlength={45}
                    value={state.beneficiaryName}
                    target={'beneficiaryName'}
                    onChange={handleChange}
                    register={register}
                    validation={{ required: true, pattern: /^[ァ-ヴーぁ-ん０-９ａ-ｚＡ-Ｚ\u30e0-\u9fcf\s]+$/, maxLength: 45 }}
                  ></TextForm>
                  <div className={styles['error']}>
                    {errors.beneficiaryName && errors.beneficiaryName.type === 'required' && <span role="alert">必須項目です。</span>}
                    {errors.beneficiaryName && errors.beneficiaryName.type === 'pattern' && (
                      <span role="alert">全角文字で入力してください。</span>
                    )}
                    {errors.beneficiaryName && errors.beneficiaryName.type === 'maxLength' && (
                      <span role="alert">45文字以内で入力してください。</span>
                    )}
                  </div>
                </InputItem>
              </div>
              <div className={styles['item-area']}>
                <InputItem title="郵便番号">
                  <TextForm
                    maxlength={3}
                    value={state.postalCodeFirst}
                    target={'postalCodeFirst'}
                    width={'50px'}
                    onChange={handleChange}
                    register={register}
                    validation={{ required: true, pattern: /^[0-9]+$/, minLength: 3, maxLength: 3 }}
                  ></TextForm>
                  -
                  <TextForm
                    maxlength={4}
                    value={state.postalCodeSecond}
                    target={'postalCodeSecond'}
                    width={'70px'}
                    onChange={handleChange}
                    register={register}
                    validation={{ required: true, pattern: /^[0-9]+$/, minLength: 4, maxLength: 4 }}
                  ></TextForm>
                  <div className={styles['error']}>
                    {((errors.postalCodeFirst && errors.postalCodeFirst.type === 'required') ||
                      (errors.postalCodeSecond && errors.postalCodeSecond.type === 'required')) && <span role="alert">必須項目です。</span>}
                    {((errors.postalCodeFirst && errors.postalCodeFirst.type === 'pattern') ||
                      (errors.postalCodeSecond && errors.postalCodeSecond.type === 'pattern')) && (
                      <span role="alert">半角数字で入力してください。</span>
                    )}
                    {((errors.postalCodeFirst && errors.postalCodeFirst.type === 'minLength') ||
                      (errors.postalCodeFirst && errors.postalCodeFirst.type === 'maxLength') ||
                      (errors.postalCodeSecond && errors.postalCodeSecond.type === 'minLength') ||
                      (errors.postalCodeSecond && errors.postalCodeSecond.type === 'maxLength')) && (
                      <span role="alert">7桁で入力してください。</span>
                    )}
                  </div>
                </InputItem>
              </div>
              <div className={styles['item-area']}>
                <InputItem title="住所 ※建物名・号室までご記入ください。">
                  <TextAreaForm
                    maxlength={150}
                    value={state.address}
                    target={'address'}
                    onChange={handleChange}
                    register={register}
                    validation={{ required: true, maxLength: 150 }}
                  ></TextAreaForm>
                  <div className={styles['error']}>
                    {errors.address && errors.address.type === 'required' && <span role="alert">必須項目です。</span>}
                    {errors.address && errors.address.type === 'maxLength' && <span role="alert">150文字以内で入力してください。</span>}
                  </div>
                </InputItem>
              </div>
            </>
          )}
          <InputItem title="保険金受取口座情報入力" description={['保険金をお受け取りになる口座情報をご入力ください。', '']}>
            <RadioBtnForm
              value={state.insuranceReceiptAccountInfo}
              onChange={handleChange}
              target={'insuranceReceiptAccountInfo'}
              RadioBtnOptions={InsuranceReceiptAccountInfoOptionsList}
            ></RadioBtnForm>
          </InputItem>
          {/* 口座情報入力欄表示時かつゆうちょ銀行以外で表示 */}
          {state.accountInfoViewFlg && state.insuranceReceiptAccountInfo === '0' && (
            <>
              <div className={styles['item-area']}>
                <InputItem title={'銀行名'}>
                  <TextForm
                    maxlength={15}
                    value={state.bankName}
                    target={'bankName'}
                    onChange={handleChange}
                    register={register}
                    validation={{
                      required: true,
                      pattern: /^[ァ-ヴーぁ-ん０-９ａ-ｚＡ-Ｚ\u30e0-\u9fcf\s]+$/,
                      maxLength: 15
                    }}
                  ></TextForm>
                  <div className={styles['error']}>
                    {errors.bankName && errors.bankName.type === 'required' && <span role="alert">必須項目です。</span>}
                    {errors.bankName && errors.bankName.type === 'pattern' && <span role="alert">全角文字で入力してください。</span>}
                    {errors.bankName && errors.bankName.type === 'maxLength' && <span role="alert">15文字以内で入力してください。</span>}
                  </div>
                </InputItem>
              </div>
              <div className={styles['item-area']}>
                <InputItem title={'銀行種類選択'}>
                  <SelectBoxForm
                    value={state.bankType}
                    target={'bankType'}
                    onChange={handleChange}
                    SelectBoxOptions={bankTypeList}
                  ></SelectBoxForm>
                </InputItem>
              </div>
              <div className={styles['item-area']}>
                <InputItem title={'店舗名'}>
                  <TextForm
                    maxlength={15}
                    value={state.branchName}
                    target={'branchName'}
                    onChange={handleChange}
                    register={register}
                    validation={{ required: true, pattern: /^[ァ-ヴーぁ-ん０-９ａ-ｚＡ-Ｚ\u30e0-\u9fcf\s]+$/, maxLength: 15 }}
                  ></TextForm>
                  <div className={styles['error']}>
                    {errors.branchName && errors.branchName.type === 'required' && <span role="alert">必須項目です。</span>}
                    {errors.branchName && errors.branchName.type === 'pattern' && <span role="alert">全角文字で入力してください。</span>}
                    {errors.branchName && errors.branchName.type === 'maxLength' && (
                      <span role="alert">15文字以内で入力してください。</span>
                    )}
                  </div>
                </InputItem>
              </div>
              <div className={styles['item-area']}>
                <InputItem title={'店舗種類選択'}>
                  <SelectBoxForm
                    value={state.branchType}
                    target={'branchType'}
                    onChange={handleChange}
                    SelectBoxOptions={branchTypeList}
                  ></SelectBoxForm>
                </InputItem>
              </div>
              <div className={styles['item-area']}>
                <InputItem title={'口座種類'}>
                  <SelectBoxForm
                    value={state.accountType}
                    target={'accountType'}
                    onChange={handleChange}
                    SelectBoxOptions={accountTypeList}
                  ></SelectBoxForm>
                </InputItem>
              </div>
              <div className={styles['item-area']}>
                <InputItem title="店番号(半角数字)">
                  <TextForm
                    maxlength={3}
                    value={state.branchNumber}
                    target={'branchNumber'}
                    onChange={handleChange}
                    register={register}
                    validation={{ pattern: /^[0-9]+$/, maxLength: 3 }}
                  ></TextForm>
                  <div className={styles['error']}>
                    {errors.branchNumber && errors.branchNumber.type === 'pattern' && (
                      <span role="alert">半角数字で入力してください。</span>
                    )}
                    {errors.branchNumber && errors.branchNumber.type === 'maxLength' && (
                      <span role="alert">3文字以内で入力してください。</span>
                    )}
                  </div>
                </InputItem>
              </div>
              <div className={styles['item-area']}>
                <InputItem title={'口座番号(半角数字)'}>
                  <TextForm
                    maxlength={7}
                    value={state.accountNumber}
                    target={'accountNumber'}
                    onChange={handleChange}
                    register={register}
                    validation={{ required: true, pattern: /^[0-9]+$/, maxLength: 7 }}
                  ></TextForm>
                  <div className={styles['error']}>
                    {errors.accountNumber && errors.accountNumber.type === 'required' && <span role="alert">必須項目です。</span>}
                    {errors.accountNumber && errors.accountNumber.type === 'pattern' && (
                      <span role="alert">半角数字で入力してください。</span>
                    )}
                    {errors.accountNumber && errors.accountNumber.type === 'maxLength' && (
                      <span role="alert">7文字以内で入力してください。</span>
                    )}
                  </div>
                </InputItem>
              </div>
            </>
          )}
          {/* 口座情報入力欄表示時かつゆうちょ銀行で表示 */}
          {state.accountInfoViewFlg && state.insuranceReceiptAccountInfo === '1' && (
            <>
              <div className={styles['item-area']}>
                <InputItem title={'通帳記号'}>
                  <TextForm
                    maxlength={5}
                    value={state.passbookSign}
                    target={'passbookSign'}
                    onChange={handleChange}
                    register={register}
                    validation={{ required: true, pattern: /^[0-9]+$/, maxLength: 5 }}
                  ></TextForm>
                  <div className={styles['error']}>
                    {errors.passbookSign && errors.passbookSign.type === 'required' && <span role="alert">必須項目です。</span>}
                    {errors.passbookSign && errors.passbookSign.type === 'pattern' && (
                      <span role="alert">半角数字で入力してください。</span>
                    )}
                    {errors.passbookSign && errors.passbookSign.type === 'maxLength' && (
                      <span role="alert">5文字以内で入力してください。</span>
                    )}
                  </div>
                </InputItem>
              </div>
              <div className={styles['item-area']}>
                <InputItem title={'通帳番号'}>
                  <TextForm
                    maxlength={8}
                    value={state.passbookNumber}
                    target={'passbookNumber'}
                    onChange={handleChange}
                    register={register}
                    validation={{ required: true, pattern: /^[0-9]+$/, maxLength: 8 }}
                  ></TextForm>
                  <div className={styles['error']}>
                    {errors.passbookNumber && errors.passbookNumber.type === 'required' && <span role="alert">必須項目です。</span>}
                    {errors.passbookNumber && errors.passbookNumber.type === 'pattern' && (
                      <span role="alert">半角数字で入力してください。</span>
                    )}
                    {errors.passbookNumber && errors.passbookNumber.type === 'maxLength' && (
                      <span role="alert">8文字以内で入力してください。</span>
                    )}
                  </div>
                </InputItem>
              </div>
            </>
          )}
          {/* 口座情報入力欄表示時に表示 */}
          {state.accountInfoViewFlg && (
            <div className={styles['item-area']}>
              <InputItem title={'口座名義人(カナ)'}>
                <TextForm
                  maxlength={33}
                  value={state.accountHolder}
                  target={'accountHolder'}
                  onChange={handleChange}
                  register={register}
                  validation={{
                    required: true,
                    pattern: /^[a-zA-Zａ-ｚＡ-Ｚ0-9０-９ァ-ヴｦ-ﾟ\s/ヷヰヸヱヹヺ，,．.‐－ー・（(）)／/「｢」｣￥¥-]+$/,
                    maxLength: 33
                  }}
                ></TextForm>
                <div className={styles['error']}>
                  {errors.accountHolder && errors.accountHolder.type === 'required' && <span role="alert">必須項目です。</span>}
                  {errors.accountHolder && errors.accountHolder.type === 'pattern' && <span role="alert">カナで入力してください。</span>}
                  {errors.accountHolder && errors.accountHolder.type === 'maxLength' && (
                    <span role="alert">33文字以内で入力してください。</span>
                  )}
                </div>
              </InputItem>
            </div>
          )}
        </InfoArea>
        <InfoArea title="その他特記事項" requireStatus={2}>
          <p className={styles['note-message']}>上記に追加する情報、ご要望事項があれば、ご入力ください。（300文字以内）</p>
          <TextAreaForm
            maxlength={300}
            value={state.note}
            target={'note'}
            onChange={handleChange}
            register={register}
            validation={{ maxLength: 300 }}
          ></TextAreaForm>
          <div className={styles['error']}>
            {errors.note && errors.note.type === 'maxLength' && <span role="alert">300文字以内で入力してください。</span>}
          </div>
        </InfoArea>
        <div className={styles['btn-area']}>
          <CommonButton
            label="入力内容の確認"
            onClick={handleSubmit(onSubmit, onError)}
            color={'#fff'}
            backGroundColor={'#00bfff'}
          ></CommonButton>
        </div>
        <div className={styles['output-area']}>
          {apiError.length !== 0 && (
            <p id="apiError" role="alert">
              {apiError}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
